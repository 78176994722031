import React from 'react'
import { Link } from 'gatsby'
//import github from '../img/github-icon.svg'
//import logo from '../img/logo.svg'
import './Navbar.sass'


const Navbar = class extends React.Component {

  componentDidMount() {
    // Get all "navbar-burger" elements
   const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    // Check if there are any navbar burgers
   if ($navbarBurgers.length > 0) {

     // Add a click event on each of them
     $navbarBurgers.forEach( el => {
       el.addEventListener('click', () => {

         // Get the target from the "data-target" attribute
         const target = el.dataset.target;
         const $target = document.getElementById(target);

         // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
         el.classList.toggle('is-active');
         $target.classList.toggle('is-active');

       });
     });
   }
 }

 render() {
   return (

  <nav className="navbar is-transparent navbar is-fixed-top" role="navigation" aria-label="main-navigation">
    <div className="container">
      <div className="navbar-brand">
        <Link to="/" className="navbar-item is-transparent" title="Logo">
          <h1 style={{display: 'flex', alignItems: 'center', margin: 0, height: '100%'}}>Tim and Victoria</h1>
        </Link>
        {/* Hamburger menu */}
        <div className="navbar-burger burger" data-target="navMenu">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div id="navMenu" className="navbar-menu overlay">
        <div className="navbar-end has-text-centered">
          <Link className="navbar-item" to="/about">
            About
          </Link>
          <Link className="navbar-item" to="/">
            Posts
          </Link>
          {/*
            <Link className="navbar-item" to="/products">
              Products
            </Link>
            <Link className="navbar-item" to="/contact">
              Contact
            </Link>
            <Link className="navbar-item" to="/contact/examples">
              Form Examples
            </Link>
          */}

        </div>
        {/*
          <div className="navbar-end has-text-centered">
            <a
              className="navbar-item"
              href="https://github.com/AustinGreen/gatsby-netlify-cms-boilerplate"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon">
                <img src={github} alt="Github" />
              </span>
            </a>
          </div>
        */}

      </div>
    </div>
  </nav>
  )}
}

export default Navbar
